<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-6">
                    <div class="form-group">
                        <strong>Tipo de Documento:</strong>
                        <select class="form-control form-control-sm" :name="data.document_type.field_name" :id="data.document_type.field_id" v-model="document_type">
                            <option v-for="(type, index) in document_types" 
                                :key="index" :value="type.id"
                                :selected="type.id == document_type">
                                {{ type.text }}
                            </option>
                        </select>
                    </div>
                </div>
                </div>
            </div>


            <div class="col-xs-12 col-sm-6 col-md-6">
                <div class="form-group">
                    <strong>Razón Social:</strong>
                    <input type="text" class="form-control form-control-sm" :name="data.document_name.field_name" :id="data.document_name.field_id" v-model="document_name" v-on:keyup.enter="getDocumentName" autocomplete="off">
                    <div class="panel-footer" v-if="this.clients_name.length > 0">
                        <span @click="removeFooter"><i class="fas fa-times"></i></span>
                        <ul class="list-group">
                            <li class="list-group-item" v-for="(client, index) in clients_name" 
                            :key="index"
                            @click="getItem(client)">{{ client.document_name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
             <div class="col-xs-12 col-sm-3 col-md-3">
                <div class="form-group">
                    <strong>N° Documento:</strong>
                    <input type="text" class="form-control form-control-sm" :name="data.document_id.field_name" :id="data.document_id.field_id" v-model="document_id" v-on:keyup.enter="getDocumentId" autocomplete="off">
                    <div class="panel-footer" v-if="this.clients_id.length > 0">
                        <span @click="removeFooter"><i class="fas fa-times"></i></span>
                        <ul class="list-group">
                            <li class="list-group-item" v-for="(client, index) in clients_id" 
                            :key="index"
                            @click="getItem(client)">{{ client.document_name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-3">
                <div class="form-group">
                    <strong>Consultar:</strong>
                    <a href="#" class="btn btn-sm btn-primary d-block" @click.prevent="showSunatReniec()">SUNAT/RENIEC</a>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="form-group">
                    <strong>Persona de Contacto:</strong>
                    <input type="text" class="form-control form-control-sm" :name="data.contact.field_name" :id="data.contact.field_id" v-model="contact" placeholder="">
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="form-group">
                    
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="form-group">
                    <strong>Teléfono/Celular:</strong>
                    <input type="text" class="form-control form-control-sm" :name="data.phone.field_name" :id="data.phone.field_id" v-model="phone" placeholder="">
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="form-group">
                    <strong>Correo Electrónico:</strong>
                    <input type="email" class="form-control form-control-sm" :name="data.email.field_name" :id="data.email.field_id" v-model="email" placeholder="">
                </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label><strong>Departamento:</strong></label>
                    <div class="input-group">
                        <select class="form-control form-control-sm" :name="data.location_one.field_name" :id="data.location_one.field_id" @change="onChangeone($event.target.value)">
                            <option value="" selected>Selecciona un departamento</option>
                            <option v-for="(one, index) in locations_one" 
                                    :key="index" :value="one.id"
                                    :selected="one.id == data.location_one.id">
                                    {{ one.name }}
                            </option>

                        </select>
                    </div>                    
                </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label><strong>Provincia:</strong></label>
                    <div class="input-group">
                        <select class="form-control form-control-sm" :name="data.location_two.field_name" :id="data.location_two.field_id" @change="onChangetwo($event.target.value)">
                            <option value="" selected>Selecciona una Provincia</option>
                            <option v-for="(two, index) in locations_two" 
                                    :key="index" :value="two.id"
                                    :selected="two.id == data.location_two.id">
                                    {{ two.name }}
                            </option>
                        </select>
                    </div>                    
                </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label><strong>Distrito:</strong></label>
                    <div class="input-group">
                        <select class="form-control form-control-sm" :name="data.location_three.field_name" :id="data.location_three.field_id" @change="onChangethree($event.target.value)">
                            <option value="" selected>Selecciona un distrito</option>
                            <option v-for="(three, index) in locations_three" 
                                    :key="index" :value="three.id"
                                    :selected="three.id == data.location_three.id">
                                    {{ three.name }}
                            </option>
                        </select>
                    </div>                    
                </div>
            </div>


            <div class="col-xs-12 col-sm-12 col-md-12">
                <div class="form-group">
                    <strong>Dirección:</strong>
                    <input type="text" class="form-control form-control-sm" :name="data.address.field_name" :id="data.address.field_id" v-model="address" placeholder="">
                </div>
            </div>



            <!--SUNAT/RENIEC modal-->
            <div id="consult" class="modal fade" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <form action="" v-on:submit.prevent="getSunat()">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Consultar N° de Documento</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" style="min-width:300px;">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <strong>Tipo de Documento:</strong>
                                        <select class="form-control form-control-sm" v-model="consult.document_type">
                                            <option v-for="(type, index) in document_types" 
                                                :key="index" :value="type.id">
                                                {{ type.text }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <strong>N° de Documento:</strong>
                                        <input class="form-control form-control-sm" type="text" v-model="consult.document_id">
                                    </div>
                                </div>

                                <div class="col-md-12 text-center">
                                    <div class="spinner-grow text-primary" role="status" v-if="isVisible">
                                        <span class="sr-only">Cargando...</span>
                                    </div>
                                </div>
                            </div>

                            

                            
                        </div>
                        <div class="modal-footer" v-if="isVisibleBtn">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="submit" class="btn btn-primary">Consultar</button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>










        </div>
    </div>
</template>
<script >
    export default {
        name: 'formclients',
        props: ['formdata'],
        data(){
            return{
                clients_name: [],
                clients_id: [],
                data: JSON.parse(this.formdata),
                document_types: JSON.parse(this.formdata).document_type.data,
                document_type: JSON.parse(this.formdata).document_type.text,
                document_name: JSON.parse(this.formdata).document_name.text,
                document_id: JSON.parse(this.formdata).document_id.text,
                contact: JSON.parse(this.formdata).contact.text,
                phone: JSON.parse(this.formdata).phone.text,
                email: JSON.parse(this.formdata).email.text,
                one: '',
                locations_one: [],
                two: '',
                locations_two: [],
                three: '',
                locations_three: [],
                address: JSON.parse(this.formdata).address.text,
                consult: {
                    document_type: '',
                    document_id: '',
                },
                isVisible: false,
                isVisibleBtn: false,
            }
        },
        methods:{
            getDocumentName(){
                this.clients_name = [];
                this.clients_id = [];
                const request= {
                    filter: 'document_name',
                    search: this.document_name
                };
                axios.post('/clients/controller/autocomplete',request).then((response) => {
                    //console.log(response.data);
                    this.clients_name = response.data;
                });
            },
            getlocations_one(){
                this.three = "";
                this.locations_three = [];
                this.two = "";
                this.locations_two = [];
                this.one = "";
                this.locations_one = [];

                const request = {
                        parent_id: '*',
                        location_one: '',
                        location_two: this.data.location_one.id,
                        location_three: this.data.location_two.id,
                    };

                axios.post('/locations/return', request).then((response) => {
                    //console.log(response.data); 
                    this.locations_one = response.data.location_one;
                    this.locations_two = response.data.location_two;
                    this.locations_three = response.data.location_three;
                });
            },
            onChangeone(value){
                
                this.three = "";
                this.locations_three = [];
                this.two = "";
                this.locations_two = [];
                const request = {
                        parent_id: value,
                    };
                axios.post('/locations/return', request).then((response) => {
                    this.locations_two = response.data;
                    //console.log(value);
                });
            },
            onChangetwo(value){
                this.three = "";
                this.locations_three = [];
               const request = {
                        parent_id: value,
                    };
                axios.post('/locations/return', request).then((response) => {
                    this.locations_three = response.data;
                    //console.log(value);
                });
            },
            onChangethree(value){
                //console.log(value);
            },
            getDocumentId(){
                this.clients_name = [];
                this.clients_id = [];
                const request= {
                    filter: 'document_id',
                    search: this.document_id
                };
                axios.post('/clients/controller/autocomplete',request).then((response) => {
                    //console.log(response.data);
                    this.clients_id = response.data;
                });
            },
            getItem(client){
                this.document_type = client.document_type;
                this.document_name = client.document_name;
                this.document_id = client.document_id;
                this.contact = client.contact;
                this.phone = client.phone;
                this.email = client.email;
                this.address = client.address;
                this.clients_name = [];
                this.clients_id = [];
                this.data.location_one.id = client.location_one;
                this.data.location_two.id = client.location_two;
                this.data.location_three.id = client.location_three;
                this.getlocations_one();
                const request= {
                    filter: 'document_id',
                };
                axios.get('/cart/' + client.id + '/addclient',request).then((response) => {
                    //console.log(response.data);
                });
            },
            removeFooter(){
                this.clients_name = [];
                this.clients_id = [];
            },
            showSunatReniec(){
                this.isVisible = false;
                this.isVisibleBtn = true;
                this.consult.document_type = this.document_type;
                this.consult.document_id = this.document_id;
                $('#consult').modal('show');
            },
            getSunat(){
                
                var url = '';
                switch(this.consult.document_type) {
                    case '6':
                        var url = '/clients/controller/consultsunatruc';
                        break;
                    case '1':
                        var url = '/clients/controller/consultsunatdni';
                        break;
                    default:
                        $('#consult').modal('hide');
                        toastr.error("Error de consulta");
                        this.consult.document_type = '';
                        this.consult.document_id = '';
                        return;
                }
                //console.log("Entre a consultar sunat");
                this.isVisible = true;
                this.isVisibleBtn = false;
                axios.post(url, {
                    document_type:  this.consult.document_type,
                    document_id: this.consult.document_id,
                }).then((response) => {
                    $('#consult').modal('hide');
                    toastr.success("Consulta Exitosa");
                    this.document_type = 0;
                    if (response.data.type){
                        this.document_type = response.data.type;
                    }
                    this.document_id = "";
                    if (response.data.id){
                        this.document_id = response.data.id;
                    }
                    this.document_name = "";
                    if (response.data.razon_social){
                        this.document_name = response.data.razon_social;
                    }
                    this.address = "";
                    if (response.data.domicilio_fiscal){
                        this.address = response.data.domicilio_fiscal;
                    }
                    if (response.data.location_one){
                        this.data.location_one.id = response.data.location_one;
                        this.data.location_two.id = response.data.location_two;
                        this.data.location_three.id = response.data.location_three;
                        this.getlocations_one();
                    }
                    
                    this.consult.document_type = '';
                    this.consult.document_id = '';
                    //console.log(response.data);
                }).catch(error => {
                    $('#consult').modal('hide');
                    toastr.error("Error de consulta");
                    this.consult.document_type = '';
                    this.consult.document_id = '';
                    //console.log(error);
                });
            }
        },
        created(){
            this.getlocations_one();
            if(!this.document_type || this.document_type == '' ){
                this.document_type = 0;
            }
        },
        mounted() {
            //console.log('Component mounted.')
        }
    }
</script>
<style>
.panel-footer{
    position: absolute;
    z-index: 999;
    width: 80%;
    margin-left: 0.75rem;
    margin-top: 0;
    margin-bottom: 0;

}
.panel-footer ul{
    height: 100px;
    overflow-y: scroll;
    background-color: #dee2e6;
    border-radius: 10px;
}
.panel-footer span{
    float: right;
    margin-left: 5px;
    font-size: 24px;
}
.panel-footer span:hover{
    color:red;
    cursor: pointer;
}
li.list-group-item{
    cursor: pointer;
    padding: 0 0.75rem 0 1.25rem;
}
li.list-group-item:hover{
        background-color: #f6f6f6;
}
</style>

