<template>

    <div class="card">
        <div class="card-header"><h2>Tareas</h2></div>

        <div class="card-body">
            <form action="" v-on:submit.prevent="newTask()">
                <div class="form-group">
                    <label for="task">Registrar una tarea</label>
                    <input type="text" class="form-control" name="task" v-model="keep">
                    <div class="btn-group float-right" style="padding:10px 0 0 0;">
                        <button type="submit" class="btn btn-primary">Registrar</button>
                    </div>
                    
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'taskform',
        data(){
            return{
                keep:''
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods:{
            newTask(){
                const params = {
                    keep: this.keep
                };
                this.keep = '';
                axios.post('/tasks', params)
                .then((response) => {
                    const task = response.data;
                    this.$emit('new',task);
                    toastr.success("Elemento creado");
                });

                
            }
        }
    }
</script>
