var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("strong", [_vm._v("Tipo de Documento:")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.document_type,
                      expression: "document_type"
                    }
                  ],
                  staticClass: "form-control form-control-sm",
                  attrs: {
                    name: _vm.data.document_type.field_name,
                    id: _vm.data.document_type.field_id
                  },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.document_type = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.document_types, function(type, index) {
                  return _c(
                    "option",
                    {
                      key: index,
                      domProps: {
                        value: type.id,
                        selected: type.id == _vm.document_type
                      }
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(type.text) +
                          "\n                        "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("strong", [_vm._v("Razón Social:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.document_name,
                expression: "document_name"
              }
            ],
            staticClass: "form-control form-control-sm",
            attrs: {
              type: "text",
              name: _vm.data.document_name.field_name,
              id: _vm.data.document_name.field_id,
              autocomplete: "off"
            },
            domProps: { value: _vm.document_name },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getDocumentName($event)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.document_name = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          this.clients_name.length > 0
            ? _c("div", { staticClass: "panel-footer" }, [
                _c("span", { on: { click: _vm.removeFooter } }, [
                  _c("i", { staticClass: "fas fa-times" })
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(_vm.clients_name, function(client, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "list-group-item",
                        on: {
                          click: function($event) {
                            return _vm.getItem(client)
                          }
                        }
                      },
                      [_vm._v(_vm._s(client.document_name))]
                    )
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-3 col-md-3" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("strong", [_vm._v("N° Documento:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.document_id,
                expression: "document_id"
              }
            ],
            staticClass: "form-control form-control-sm",
            attrs: {
              type: "text",
              name: _vm.data.document_id.field_name,
              id: _vm.data.document_id.field_id,
              autocomplete: "off"
            },
            domProps: { value: _vm.document_id },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getDocumentId($event)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.document_id = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          this.clients_id.length > 0
            ? _c("div", { staticClass: "panel-footer" }, [
                _c("span", { on: { click: _vm.removeFooter } }, [
                  _c("i", { staticClass: "fas fa-times" })
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(_vm.clients_id, function(client, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "list-group-item",
                        on: {
                          click: function($event) {
                            return _vm.getItem(client)
                          }
                        }
                      },
                      [_vm._v(_vm._s(client.document_name))]
                    )
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-3 col-md-3" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("strong", [_vm._v("Consultar:")]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-sm btn-primary d-block",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showSunatReniec()
                }
              }
            },
            [_vm._v("SUNAT/RENIEC")]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("strong", [_vm._v("Persona de Contacto:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.contact,
                expression: "contact"
              }
            ],
            staticClass: "form-control form-control-sm",
            attrs: {
              type: "text",
              name: _vm.data.contact.field_name,
              id: _vm.data.contact.field_id,
              placeholder: ""
            },
            domProps: { value: _vm.contact },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.contact = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("strong", [_vm._v("Teléfono/Celular:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phone,
                expression: "phone"
              }
            ],
            staticClass: "form-control form-control-sm",
            attrs: {
              type: "text",
              name: _vm.data.phone.field_name,
              id: _vm.data.phone.field_id,
              placeholder: ""
            },
            domProps: { value: _vm.phone },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phone = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("strong", [_vm._v("Correo Electrónico:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email"
              }
            ],
            staticClass: "form-control form-control-sm",
            attrs: {
              type: "email",
              name: _vm.data.email.field_name,
              id: _vm.data.email.field_id,
              placeholder: ""
            },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-4 col-md-4" }, [
        _c("div", { staticClass: "form-group" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "input-group" }, [
            _c(
              "select",
              {
                staticClass: "form-control form-control-sm",
                attrs: {
                  name: _vm.data.location_one.field_name,
                  id: _vm.data.location_one.field_id
                },
                on: {
                  change: function($event) {
                    return _vm.onChangeone($event.target.value)
                  }
                }
              },
              [
                _c("option", { attrs: { value: "", selected: "" } }, [
                  _vm._v("Selecciona un departamento")
                ]),
                _vm._v(" "),
                _vm._l(_vm.locations_one, function(one, index) {
                  return _c(
                    "option",
                    {
                      key: index,
                      domProps: {
                        value: one.id,
                        selected: one.id == _vm.data.location_one.id
                      }
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(one.name) +
                          "\n                        "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-4 col-md-4" }, [
        _c("div", { staticClass: "form-group" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "input-group" }, [
            _c(
              "select",
              {
                staticClass: "form-control form-control-sm",
                attrs: {
                  name: _vm.data.location_two.field_name,
                  id: _vm.data.location_two.field_id
                },
                on: {
                  change: function($event) {
                    return _vm.onChangetwo($event.target.value)
                  }
                }
              },
              [
                _c("option", { attrs: { value: "", selected: "" } }, [
                  _vm._v("Selecciona una Provincia")
                ]),
                _vm._v(" "),
                _vm._l(_vm.locations_two, function(two, index) {
                  return _c(
                    "option",
                    {
                      key: index,
                      domProps: {
                        value: two.id,
                        selected: two.id == _vm.data.location_two.id
                      }
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(two.name) +
                          "\n                        "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-4 col-md-4" }, [
        _c("div", { staticClass: "form-group" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "input-group" }, [
            _c(
              "select",
              {
                staticClass: "form-control form-control-sm",
                attrs: {
                  name: _vm.data.location_three.field_name,
                  id: _vm.data.location_three.field_id
                },
                on: {
                  change: function($event) {
                    return _vm.onChangethree($event.target.value)
                  }
                }
              },
              [
                _c("option", { attrs: { value: "", selected: "" } }, [
                  _vm._v("Selecciona un distrito")
                ]),
                _vm._v(" "),
                _vm._l(_vm.locations_three, function(three, index) {
                  return _c(
                    "option",
                    {
                      key: index,
                      domProps: {
                        value: three.id,
                        selected: three.id == _vm.data.location_three.id
                      }
                    },
                    [
                      _vm._v(
                        "\n                                " +
                          _vm._s(three.name) +
                          "\n                        "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("strong", [_vm._v("Dirección:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.address,
                expression: "address"
              }
            ],
            staticClass: "form-control form-control-sm",
            attrs: {
              type: "text",
              name: _vm.data.address.field_name,
              id: _vm.data.address.field_id,
              placeholder: ""
            },
            domProps: { value: _vm.address },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.address = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: { id: "consult", tabindex: "-1", role: "dialog" }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered",
              attrs: { role: "document" }
            },
            [
              _c(
                "form",
                {
                  attrs: { action: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.getSunat()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "modal-body",
                        staticStyle: { "min-width": "300px" }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("strong", [_vm._v("Tipo de Documento:")]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.consult.document_type,
                                      expression: "consult.document_type"
                                    }
                                  ],
                                  staticClass: "form-control form-control-sm",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.consult,
                                        "document_type",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.document_types, function(
                                  type,
                                  index
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      domProps: { value: type.id }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(type.text) +
                                          "\n                                        "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("strong", [_vm._v("N° de Documento:")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.consult.document_id,
                                    expression: "consult.document_id"
                                  }
                                ],
                                staticClass: "form-control form-control-sm",
                                attrs: { type: "text" },
                                domProps: { value: _vm.consult.document_id },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.consult,
                                      "document_id",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-12 text-center" }, [
                            _vm.isVisible
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "spinner-grow text-primary",
                                    attrs: { role: "status" }
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Cargando...")
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isVisibleBtn
                      ? _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button", "data-dismiss": "modal" }
                            },
                            [_vm._v("Cancelar")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "submit" }
                            },
                            [_vm._v("Consultar")]
                          )
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-6" }, [
      _c("div", { staticClass: "form-group" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("strong", [_vm._v("Departamento:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("strong", [_vm._v("Provincia:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("strong", [_vm._v("Distrito:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [
        _vm._v("Consultar N° de Documento")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }