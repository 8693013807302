
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

//Importar componente de Jquery UI.
import $ from 'jquery';

window.$ = window.jQuery = $;
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/i18n/datepicker-es.js';
import 'jquery-ui/ui/widgets/slider.js';
//https://trentrichardson.com/examples/timepicker/
import 'jquery-ui-timepicker-addon/src/jquery-ui-timepicker-addon.js';


window.Vue = require('vue');

/*Desactivar modo developer de Vue */
//Vue.config.productionTip = false;
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

//Vue.component('vue-component', require('./components/VueComponent.vue'));

Vue.component('mytask', require('./components/MyTask.vue').default);
Vue.component('task', require('./components/Task.vue').default);
Vue.component('taskform', require('./components/TaskForm.vue').default);

//Vue.component('mysale', require('./components/MySale.vue'));

Vue.component('myinprocessnotification', require('./components/MyInProcessNotification.vue').default);
Vue.component('myquotenotification', require('./components/MyQuoteNotification.vue').default);

Vue.component('locations', require('./components/Locations.vue').default);
Vue.component('formclients', require('./components/FormClients.vue').default);

// const files = require.context('./', true, /\.vue$/i)

// files.keys().map(key => {
//     return Vue.component(_.last(key.split('/')).split('.')[0], files(key))
// })

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});