<template>
        <div class="">
            <taskform 
            @new="addTask"
            ></taskform>
            <hr>
            <div class="row">
            <task 
            v-for="(task, index) in tasks" 
            :key="task.id"
            :task="task"
            @update="updateTask(index, ...arguments)"
            @delete="deleteTask(index)"
            ></task>
            </div>
        </div>
</template>

<script>

    export default {
        name: 'mytask',
        data(){
            return{
                tasks: []
            }
        },
        mounted() {
            axios.get('/tasks').then((response) => {
                this.tasks = response.data;
            })
        },
        methods:{
            addTask(task){
               //bottom list
               //this.tasks.push(task);
               //top list
               this.tasks.unshift(task); 
            },
            updateTask(index,task){
                this.tasks[index] = task;
            },
            deleteTask(index){
                this.tasks.splice(index,1);
            }
        }
    }
</script>
<style>
.card-priority{
-webkit-box-shadow: 0px 0px 12px 2px rgba(230,0,0,1);
-moz-box-shadow: 0px 0px 12px 2px rgba(230,0,0,1);
box-shadow: 0px 0px 12px 2px rgba(230,0,0,1);
}
</style>
