var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c("div", [
        _c("a", { staticClass: "nav-link", attrs: { href: "/quotealarms" } }, [
          _c(
            "span",
            {
              staticClass: "badge badge-light blink",
              staticStyle: { "background-color": "#bb3333" }
            },
            [
              _c(
                "div",
                { staticClass: "blink", staticStyle: { color: "#FFF" } },
                [_vm._v(_vm._s(_vm.qnotifications))]
              )
            ]
          ),
          _vm._v(" "),
          _c("i", { staticClass: "far fa-bell" }),
          _vm._v(" "),
          _c("span", { staticClass: "d-lg-none" }, [_vm._v("Notificaciones")])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }