<template>
    
    <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-4">
            <div class="form-group">
                <label><strong>Departamento:</strong></label>
                <div class="input-group">
                    <select class="form-control form-control-sm" :name="location.location_one.field_name" :id="location.location_one.field_id" @change="onChangeone($event.target.value)">
                        <option value="" selected>Selecciona un departamento</option>
                        <option v-for="(one, index) in locations_one" 
                                :key="index" :value="one.id"
                                :selected="one.id == location.location_one.id">
                                {{ one.name }}
                        </option>

                    </select>
                </div>                    
            </div>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-4">
            <div class="form-group">
                <label><strong>Provincia:</strong></label>
                <div class="input-group">
                    <select class="form-control form-control-sm" :name="location.location_two.field_name" :id="location.location_two.field_id" @change="onChangetwo($event.target.value)">
                        <option value="" selected>Selecciona una Provincia</option>
                        <option v-for="(two, index) in locations_two" 
                                :key="index" :value="two.id"
                                :selected="two.id == location.location_two.id">
                                {{ two.name }}
                        </option>
                    </select>
                </div>                    
            </div>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4">
            <div class="form-group">
                <label><strong>Distrito:</strong></label>
                <div class="input-group">
                    <select class="form-control form-control-sm" :name="location.location_three.field_name" :id="location.location_three.field_id" @change="onChangethree($event.target.value)">
                        <option value="" selected>Selecciona un distrito</option>
                        <option v-for="(three, index) in locations_three" 
                                :key="index" :value="three.id"
                                :selected="three.id == location.location_three.id">
                                {{ three.name }}
                        </option>
                    </select>
                </div>                    
            </div>
        </div>

    </div>

</template>
<script >
    export default {
        name: 'locations',
        props: ['locations'],
        data(){
            return{
                location: JSON.parse(this.locations),
                one: '',
                locations_one: [],
                two: '',
                locations_two: [],
                three: '',
                locations_three: []
            }
        },
        methods:{
            getlocations_one(){
                const request = {
                        parent_id: '*',
                        location_one: '',
                        location_two: this.location.location_one.id,
                        location_three: this.location.location_two.id,
                    };

                axios.post('/locations/return', request).then((response) => {
                    console.log(response.data.one); 
                    this.locations_one = response.data.location_one;
                    this.locations_two = response.data.location_two;
                    this.locations_three = response.data.location_three;
                });
            },
            onChangeone(value){
                
                this.three = "";
                this.locations_three = [];
                this.two = "";
                this.locations_two = [];
                const request = {
                        parent_id: value,
                    };
                axios.post('/locations/return', request).then((response) => {
                    this.locations_two = response.data;
                    console.log(value);
                });
            },
            onChangetwo(value){
                this.three = "";
                this.locations_three = [];
               const request = {
                        parent_id: value,
                    };
                axios.post('/locations/return', request).then((response) => {
                    this.locations_three = response.data;
                    console.log(value);
                });
            },
            onChangethree(value){
                console.log(value);
            }

        },
        created(){
            //console.log(this.location)
            this.getlocations_one();
        },
        mounted() {

            console.log('Component mounted.');
        }
    }
</script>
