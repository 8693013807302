var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.newTask()
            }
          }
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "task" } }, [
              _vm._v("Registrar una tarea")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.keep,
                  expression: "keep"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", name: "task" },
              domProps: { value: _vm.keep },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.keep = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm._m(1)
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h2", [_vm._v("Tareas")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "btn-group float-right",
        staticStyle: { padding: "10px 0 0 0" }
      },
      [
        _c(
          "button",
          { staticClass: "btn btn-primary", attrs: { type: "submit" } },
          [_vm._v("Registrar")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }