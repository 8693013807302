var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "col-md-12 col-lg-6",
      staticStyle: { "padding-bottom": "10px" }
    },
    [
      _c(
        "div",
        {
          staticClass: "card bg-light",
          class: [_vm.task.priority == 1 ? "card-priority" : ""]
        },
        [
          _c("div", { staticClass: "card-header" }, [
            _vm._v(_vm._s(_vm.task.updated_at))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _vm.editMode
              ? _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.task.keep,
                      expression: "task.keep"
                    }
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.task.keep },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.task, "keep", $event.target.value)
                    }
                  }
                })
              : _c("p", [_vm._v(_vm._s(_vm.task.keep))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _vm.editMode ? _c("span", [_vm._v("Prioridad")]) : _vm._e(),
            _vm._v(" "),
            _vm.editMode
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.task.priority,
                      expression: "task.priority"
                    }
                  ],
                  attrs: {
                    type: "checkbox",
                    "true-value": "1",
                    "false-value": "0"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.task.priority)
                      ? _vm._i(_vm.task.priority, null) > -1
                      : _vm._q(_vm.task.priority, "1")
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.task.priority,
                        $$el = $event.target,
                        $$c = $$el.checked ? "1" : "0"
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.task, "priority", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.task,
                              "priority",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.task, "priority", $$c)
                      }
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "btn-group float-right" }, [
              _vm.editMode
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      on: {
                        click: function($event) {
                          return _vm.onClickUpdate()
                        }
                      }
                    },
                    [_c("i", { staticClass: "far fa-save" })]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-info",
                      on: {
                        click: function($event) {
                          return _vm.onClickEdit()
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-edit" })]
                  ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  on: {
                    click: function($event) {
                      return _vm.onClickDelete()
                    }
                  }
                },
                [_c("i", { staticClass: "far fa-trash-alt" })]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }