<template>
    <div v-if="isVisible">
        <a class="nav-link"  href="/quotealarms">
            <span class="badge badge-light blink" style="background-color:#bb3333;">
               <div class="blink" style="color:#FFF;">{{ qnotifications }}</div> 
            </span>
            <i class="far fa-bell"></i>
            <span class="d-lg-none">Notificaciones</span>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'quotenotification',
        props: ['reloadtime'],
        data(){
            return{
                time: this.reloadtime,
                isVisible: false,
                qnotifications:'',
                intervalid:'',
                interanimation:'',
            }
        },
        methods:{
            checkqnotifications(){
                 var url = '/quotesalarms/countnotifications';
                axios.get(url).then((response) => {
                    this.qnotifications = response.data;
                    if(this.qnotifications >= 1){
                        this.isVisible = true;
                    }else{
                        this.isVisible = false;
                    }
                });
            },
            showFunction(){
                this.intervalid = setInterval(function(){
                    this.checkqnotifications();
                }.bind(this), this.time);
                this.interanimation = setInterval(function(){
                    this.animation();
                }.bind(this), 1000);
            },
            animation(){
                $('.blink').fadeOut(500);
                $('.blink').fadeIn(500);
            }
        },
        created(){
            this.audio = new Audio(this.path);
            this.checkqnotifications();
            this.showFunction();
            
        },
        mounted() {
            //console.log('Quote Notifications Component mounted.')
        },

    }
</script>