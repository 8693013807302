<template>
    <div v-if="isVisible">
        <a class="nav-link"  href="/quotes/controller/search?status=2">
            <span class="badge badge-light blink" style="background-color:#bb3333;">
               <div class="blink" style="color:#FFF;">{{ inprocess }}</div> 
            </span>
            <i class="fas fa-check"></i>
            <span class="d-lg-none">Procesar</span>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'inprocess',
        props: ['soundpath','reloadtime'],
        data(){
            return{
                path: this.soundpath,
                time: this.reloadtime,
                audio: '',
                isVisible: false,
                inprocess:'',
                intervalid:'',
                interanimation:'',
            }
        },
        methods:{
            checkInProcess(){
                 var url = '/quotes/controller/countinprocess';
                axios.get(url).then((response) => {
                    this.inprocess = response.data;
                    if(this.inprocess >= 1){
                        this.isVisible = true;
                    }else{
                        this.isVisible = false;
                    }
                });
            },
            checkInProcessSound(){
                 var url = '/quotes/controller/countinprocess';
                axios.get(url).then((response) => {
                    this.inprocess = response.data;
                    if(this.inprocess >= 1){
                        this.isVisible = true;
                        this.audio.play();
                    }else{
                        this.isVisible = false;
                    }
                });
            },
            showFunction(){
                this.intervalid = setInterval(function(){
                    this.checkInProcessSound();
                }.bind(this), this.time);
                this.interanimation = setInterval(function(){
                    this.animation();
                }.bind(this), 1000);
            },
            animation(){
                $('.blink').fadeOut(500);
                $('.blink').fadeIn(500);
            }
        },
        created(){
            this.audio = new Audio(this.path);
            this.checkInProcess();
            this.showFunction();
            
        },
        mounted() {
            //console.log('In Process Component mounted.')
        },

    }
</script>