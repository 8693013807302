var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("taskform", { on: { new: _vm.addTask } }),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.tasks, function(task, index) {
          return _c("task", {
            key: task.id,
            attrs: { task: task },
            on: {
              update: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.updateTask.apply(void 0, [index].concat(argsArray))
              },
              delete: function($event) {
                return _vm.deleteTask(index)
              }
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }