<template>

    <div class="col-md-12 col-lg-6" style="padding-bottom:10px;">
        <div :class="[ task.priority == 1 ? 'card-priority' : '' ]" class="card bg-light">
            <div class="card-header">{{ task.updated_at }}</div>
        
            <div class="card-body">
                <textarea v-if="editMode" class="form-control" v-model="task.keep"></textarea>
                <p v-else>{{ task.keep }}</p>

            </div>
            <div class="card-footer">
            <span v-if="editMode">Prioridad</span>
                <input type="checkbox" v-if="editMode" v-model="task.priority" true-value="1" false-value="0">
                
                <div class="btn-group float-right">
                <button v-if="editMode" class="btn btn-success" v-on:click="onClickUpdate()">
                    <i class="far fa-save"></i>
                </button>
                <button v-else class="btn btn-info" v-on:click="onClickEdit()">
                    <i class="fas fa-edit"></i>
                </button>
                <button class="btn btn-danger" v-on:click="onClickDelete()">
                    <i class="far fa-trash-alt"></i>
                </button>
                </div>
            </div>
        </div>
    </div>
                
</template>

<script>
    export default {
        name: 'task',
        props:['task'],
        data(){
            return{
                editMode: false,
            };
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods:{
            onClickDelete(){
                axios.delete('/tasks/'+this.task.id).then((response) => {
                    this.$emit('delete');
                    toastr.warning("Elemento eliminado");
                });
            },
            onClickEdit(){
                this.editMode = true;
            },
            onClickUpdate(){
                
                const params = {
                    keep: this.task.keep,
                    priority: this.task.priority
                };
                axios.put('/tasks/'+this.task.id,params).then((response) => {
                    this.editMode = false;
                    const task = response.data;
                    this.$emit('update', this.task);
                    toastr.success("Elemento actualizado");
                });
                
            }
        }
    }
</script>